import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/2C/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/2C/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/2C/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/2C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/3B/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/3B/6.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly2C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 2,
        cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
  },
  {
    src: p2,
    source:p2,
    rows: 1.5,
    cols: 2,
  },
  
      
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Patriotism 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/>
        Class: 2C   Date: 22 & 23 JANUARY 2024      
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         “Gratitude is the inward feeling of kindness received. Thankfulness is the natural impulse to express that feelings. Thanksgiving is the following of that impulse”
        <br/>
        </Typography> */}
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 2C presented their assembly on the topic, ‘Patriotism’ on Monday, 22 January and Tuesday, 23 January 2024. The assembly began with the introduction of the topic in the form of a conversation explaining the meaning and the significance of Patriotism followed by a profound thought on Patriotism.   
        <br></br>
        On day 2 students presented a harmonious rendition of the song “My India So Beautiful!” expressing their love for their country and praising its rich culture. Overall the assembly imparted patriotism among the students and brought out the zeal of Republic Day celebration during the week. The audience was charmed by the performance.
        <br></br>
          <div>
          </div>
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        "We are true patriots! We love India!"
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly2C2023;